// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-button, ion-card-subtitle {
    text-transform: none !important;
}

ion-select {
    color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
  }

ion-alert.select-alert {
    --background: var(--ion-color-light) !important;
}

div.alert-radio-label {
    color: var(--ion-color-dark) !important;
}

ion-select {
    color: var(--ion-color-dark) !important;
}
ion-toolbar.apptitle {
    background-color: var(--ion-color-secondary);
    --background: url('/assets/img/vegecioustextlogo.svg') no-repeat center center;        
}