// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-base: #f4f5f8;
  --ion-color-contrast: #222428;
  --ion-background-color: #f4f5f8;
  --ion-background-color-shade: hsl(225, 22.2%, 86.8%);
  --ion-text-color: #222428;
  --ion-toolbar-background-color: hsl(225, 22.2%, 86.8%);
  --ion-toolbar-text-color: hsl(220, 8.1%, 16%);
  --ion-item-background-color: hsl(225, 22.2%, 67.5%);
  --ion-item-text-color: hsl(220, 8.1%, 18.9%);


  /** white **/ 
  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
    

  --ion-color-primary: #5171a9;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: hsl(218.20000000000005, 35.2%, 88.2%);
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade:  hsl(218.20000000000005, 35.2%, 44.1%);
  --ion-color-primary-tint:  hsl(218.20000000000005, 35.2%, 53.9%);

  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: hsl(0, 0%, 20%);
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade:  hsl(0, 0%, 90%);
  --ion-color-secondary-tint: hsl(0, 0%, 100%);

  --ion-color-tertiary:  #5260ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: hsl(235.10000000000002, 100%, 100%);
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: hsl(235.10000000000002, 100%, 59.5%);
  --ion-color-tertiary-tint:  hsl(235.10000000000002, 100%, 72.7%);

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: hsl(143.89999999999998, 65.4%, 10%);
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: hsl(143.89999999999998, 65.4%, 45.2%);
  --ion-color-success-tint: hsl(143.89999999999998, 65.4%, 55.2%);

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: hsl(45.60000000000002, 100%, 10.4%);
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: hsl(45.60000000000002, 100%, 46.6%);
  --ion-color-warning-tint: hsl(45.60000000000002, 100%, 56.9%);

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: hsl(352.1, 80.7%, 100%);
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: hsl(352.1, 80.7%, 53.5%);
  --ion-color-danger-tint: hsl(352.1, 80.7%, 65.4%);

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: hsl(220, 8.1%, 26.1%);
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: hsl(220, 8.1%, 13.1%);
  --ion-color-dark-tint: hsl(220, 8.1%, 16%);

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: hsl(228, 4.8%, 11.8%);
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: hsl(228, 4.8%, 53.3%);
  --ion-color-medium-tint: hsl(228, 4.8%, 65.1%);

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: $hsl(225, 22.2%, 19.3%);
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: hsl(225, 22.2%, 86.8%);
  --ion-color-light-tint: hsl(225, 22.2%, 100%);
  
  --ion-color-apple-foreground: #000000;
  --ion-color-apple-background: #FFFFFF;
}

/*
$grid-breakpoints: (
 xs: 0, // Phone (0px - 575px)
 sm: 576px, // Phablet (576px - 767px)
 md: 768px, // Tablet vertical (768px - 991px)
 lg: 992px // Tablet horizontal, Desktop (992px and above)
);

$grid-max-widths: (
 lg: 500px
);*/
  

:root[mode=ios] {
    --ion-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Tahoma, sans-serif  
}

:root[mode=md] {
    --ion-default-font: "Segoe UI", Tahoma, sans-serif
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
